import MePng from "../images/me_1.jpg"

import SkillsPythonLogo from "../images/skills_python_logo.png"
import SkillsDevOpsLogo from "../images/skills_devops_logo.png"
import SkillsBashLogo from "../images/skills_bash_logo.png"
import SkillsContainersLogo from "../images/skills_containers_logo.png"
import SkillsNginxLogo from "../images/skills_nginx_logo.png"
import SkillsCloudProvidersLogo from "../images/skills_cloud_providers_logo.png"
import SkillsDatabasesLogo from "../images/skills_databases_logo.png"
import SkillsRelationDbLogo from "../images/skills_relation_db_logo.png"
import SkillsDocumentDbLogo from "../images/skills_document_db_logo.png"
import SkillsEnglishLogo from "../images/skills_english_logo.png"

import WorkZSELogo from "../images/work_zse_logo.png"
import WorkURLogo from "../images/work_ur_logo.png"
import WorkSembotLogo from "../images/work_sembot_logo.png"
import WorkSiiLogo from "../images/work_sii_logo.png"
import WorkInfinidatLogo from "../images/work_infinidat_logo.png"
import WorkTechemLogo from "../images/work_techem_logo.png"
import WorkAdobeLogo from "../images/work_adobe_logo_2.png"

import InterestsBlockchainLogo from "../images/interests_blockchain_logo.png"
import InterestsBlenderLogo from "../images/interests_blender_logo.png"


export const content_pl = [
    {
      type: "generalTitleSegment",
      title: "Kamil Żuk",
      image: MePng,
      content: {
        "E-mail": "zukkamil.44@gmail.com",
        "Github": "git.00x097.com/tbs093a",
        "Wykształcenie": "Wyższe / Inżynier",
        "Miejsce Zamieszkania": "Rzeszów / Polska",
        "Data Urodzenia": "30.06.1997 r.",
      }
    },
    {
      type: "titleSegment",
      title: "Umiejętności",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Programowanie - Python",
      image: "", //SkillsPythonLogo,
      branchBorderColor: "#ffd748",
      mainBorderColor: null,
      firstElement: true,
      content: [
        "Django",
        "Django REST Framework",
        "Channels 3.0",
        "Celery (Task Manager)",
        "Asyncio",
      ],
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "DevOps",
      image: "", //SkillsDevOpsLogo,
      mainBorderColor: null,
      content: [],
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Automatyzacja",
      image: "", //SkillsBashLogo,
      branchBorderColor: "#4aa720",
      mainBorderColor: "#2e8cb1",
      content: [
        "Jenkins (CI/CD / Automatyzacja Procesów (Job's) / GitOps)",
        "Ansible (Automatyzacja konfiguracji środowisk developerskich / serwerowych)",
        "Bash / ZSH (Linux)",
        "Powershell (Windows)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#4aa720",
      mainBorderColor: "#2e8cb1",
    },

    {
      type: "workSubSegment",
      tabs: 1,
      title: "Konteneryzacja",
      image: "", //SkillsContainersLogo,
      branchBorderColor: "#00b7ff",
      mainBorderColor: "#2e8cb1",
      content: [
        "Docker",
        "Docker-Compose (Mikroserwisy zorientowane na pojedyńcze serwery lub debugowanie)",
        "MiniKube (Lokalne debugowanie manifestów K8S)",
        "Kubernetes (Helm / Cert-Manager / Ingress / K9S)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#00b7ff",
      mainBorderColor: "#2e8cb1",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Konfiguracja",
      image: "", //SkillsNginxLogo,
      branchBorderColor: "#d90000",
      mainBorderColor: "#2e8cb1",
      content: [
        "Apache",
        "Nginx (https-portal)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#d90000",
      mainBorderColor: "#2e8cb1",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Obsługa Cloud Provider'ów",
      image: "", //SkillsCloudProvidersLogo,
      branchBorderColor: "#f96900",
      mainBorderColor: "#2e8cb1",
      content: [
        "Hetzner",
        "OVH",
        "AWS",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#f96900",
      mainBorderColor: "#2e8cb1",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Konfiguracja & Administracja Baz Danych",
      image: "", //SkillsDatabasesLogo,
      mainBorderColor: null,
      content: [],
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Bazy Relacyjne (SQL)",
      image: "", //SkillsRelationDbLogo,
      branchBorderColor: "#7b00b3",
      mainBorderColor: "#1038a7",
      content: [
        "MariaDB",
        "MySQL",
        "PostgreSQL",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#7b00b3",
      mainBorderColor: "#1038a7",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Bazy Dokumentowe (NoSQL)",
      image: "", //SkillsDocumentDbLogo,
      branchBorderColor: "#047900",
      mainBorderColor: "#1038a7",
      content: [
        "MongoDB (Sharded Cluster)",
        "ElasticSearch (ElasticSearch / Kibana / Logstash)",
        "Redis (Cache / Task Queue)",
        "RabbitMQ (Task Queue)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#047900",
      mainBorderColor: "#1038a7",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Angielski - Poziom B2",
      image: "", //SkillsEnglishLogo,
      mainBorderColor: null,
      lastElement: true,
      content: [],
    },
    {
      type: "titleSegment",
      title: "Doświadczenie",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Zespół Szkół Elektronicznych w Rzeszowie / Technikum nr 6 - Technik Informatyk - od 2013 do 2017",
      image: WorkZSELogo,
      mainBorderColor: null,
      firstElement: true,
      noElements: true,
      content: [],
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Uniwersytet Rzeszowski - Studia Inżynierskie - Informatyka - od 2017 do 2021",
      image: WorkURLogo,
      content: [],
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Praca Inżynierska - Graficzna baza układów dłoni na potrzeby systemu rozpoznawania Polskiego Języka Migowego (PJM)",
      image: "",
      branchBorderColor: "#015198",
      mainBorderColor: "#015198",
      content: [
        "Integracja Django / Django REST Framework z oprogramowaniem Blender",
        "Integracja Djnago / Django REST Framework z bazą danych MongoDb podzielonej na fragmenty (shards)",
        "Implementacja protokołu WebSocket (moduł Channles 3.0) do monitorowania procesu renderowania układów dłoni w czasie rzeczywistym",
        "Implementacja synchronicznego API w Django REST Framework do zarządzania wyrenderowanymi obrazami oraz modelami 3D",
        "Implementacja asynchronicznego API w Django + Channels 3.0 + Redis do nadzorowania procesu renderowania na serwerze w czasie rzeczywistym",
        "Implementacja aplikacji klienckiej w ReactJS / Gatsby + Redux Toolkit do obsługi synchronicznego i asynchronicznego API",
        "Wdrożenie aplikacji w formie rozproszonej dzięki narzędziom konteneryzacji i orkiestracji Docker + Docker-Compose",
        "Implementacja skryptów Bash automatyzujących migrację / konfigurację wszystkich środowisk konteneryzacyjnych na podstawie zmeinnych środowiskowych",
        "Wykonanie modelu trójwymiarowgo dłoni przeznaczonego do renderowania realistycznych próbek (zdjęć) układów dłoni, jako materiały do nauki systemu rozpoznawania PJM",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#015198",
      mainBorderColor: "#015198",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Sembot Sp. z o. o. - DevOps Engineer & Python Developer - od 01.11.2020 do 30.10.2022",
      image: WorkSembotLogo,
      branchBorderColor: "#f5c314",
      mainBorderColor: null,
      content: [
        "Administracja serwerów (OVH / AWS / Digital Ocean)",
        "Konfiguracja serwerów (Nginx (Https-Portal) / Apache / Docker)",
        "Automatyzacja operacji rutynowych (Ansible / Jenkins pipeline / Bash scripts)",
        "Wdrażanie produkcyjne oprogramowania zorientowanego na kontenerach (Docker / Docker-Compose)",
        "Programowanie narzędzi MerchTech (Python / Django / Django REST Framework)",
        "Implementacja, konfiguracja oraz administracja baz danych do obsługi narzędzi MerchTech (Big Data) (MongoDb / Elasticsearch / MariaDb / MySQL / Redis / AWS SQS)"
      ],
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Sii Sp. z o. o. - od 10.11.2022",
      image: WorkSiiLogo,
      mainBorderColor: null,
      content: [],
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Infinidat Inc - Integration Developer - od 10.11.2022 do 24.02.2023",
      image: WorkInfinidatLogo,
      branchBorderColor: "#8fc5c6",
      mainBorderColor: "#0464a8",
      content: [
        "Utrzymywanie infrastruktury serwerów (VMware / Vcenter / Vsphere / ESXi)",
        "Wdrażanie hostów / węzłów Jenkinsa (Jenkins nodes - z różnymi wersjami distro Linux'a oraz Windows'a) pod budowę i integrację aplikacji (CI/CD)",
        "Wdrażanie hostów Vcenter / Vsphere / ESXi",
        "Rozwiązywanie problemów na etapie integracji (Jenkins - naprawa na podstawie testów)",
        "Ulepszanie procesów oraz rozwiązań integracyjnych (Jenkins pipeline / Gitlab / Python / Bash)",
        "Automatyzacja rutynowych czynności (Bash / Python / Ansible / Jenkins)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#8fc5c6",
      mainBorderColor: "#0464a8",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Projekty Wewnętrzne - DevOps Engineer - od 25.02.2023 do 27.05.2023",
      image: WorkSiiLogo,
      branchBorderColor: "#0464a8",
      mainBorderColor: "#0464a8",
      content: [
        "Utrzymywanie infrastruktury mikroserwisów (Docker / Kubernetes / Helm)",
        "Utrzymywanie infrastruktury serwerów (Linux)",
        "Automatyzacja konfiguracji serwerów (Ansible / Kubespray / Bash)",
        "Ulepszanie / Utrzymywanie procesów zautomatyzowanych (Gitlab-CI)",
        "Automatyzacja raportowania przebiegu wdrożenia CI/CD (Gitlab-CI / SonarQube)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#0464a8",
      mainBorderColor: "#0464a8",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Techem GmbH - DevOps Engineer - od 30.05.2023 do 30.11.2023",
      image: WorkTechemLogo,
      branchBorderColor: "#f87479",
      mainBorderColor: "#0464a8",
      content: [
        "Utrzymywanie Infrastruktury mikroserwisów (Docker / Docker Compose)",
        "Utrzymywanie Infrastruktury serwerów (Jenkins Agent - Windows / Jenkins Master - Linux)",
        "Automatyzacja konfiguracji serwerów (Ansible / Bash / Powershell)",
        "Automatyzacja uruchamiania testów urządzeń IrDA na środowisku CI/CD (Regresja, Merge Request, Commit) (Jenkins CI/CD / Bash / Powershell / Python - TOX)",
        "Automatyzacja bodwania i kolekcjonowania reużywalnych pakietów języka python (Jenkins CI/CD / Private PyPI / Python)",
        "Automatyzacja raportowania testów (Jenkins CI/CD / Test-Result-Analyzer / Jira Xray)",
        "Automatyzacja procesów walidujących kod (Pre-commit / Black Formatter / Flake8 / MyPY / etc.)"
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#f87479",
      mainBorderColor: "#0464a8",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Adobe Inc - DevOps Engineer & Site Reliability Engineer - od 01.12.2023",
      image: WorkAdobeLogo,
      branchBorderColor: "#fa0c00",
      mainBorderColor: "#0464a8",
      content: [
        "Przygotowywanie Root Cause Analysis (RCA) Awarii Adobe Experience Manager (AEM)",
        "Analiza Java Heap-Dumps & Thread-Dumps (Wycieki Pamięci / Operacje Wykonywane Wątków)",
        "Analiza Dashboard'ów SRE (New Relic / Splunk / Grafana)",
        "Tworzenie Dashboard'ów SRE (Splunk)",
        "Automatyzacja Analizy & Rutynowych Zadań (Bash / Python)",
        "Analiza Zachowania Infrastruktury & Serwisów (Kubernetes / Linux / AEM)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#fa0c00",
      mainBorderColor: "#0464a8",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Inside Projects - DevOps Engineer & Python Developer - from 01.11.2024",
      image: WorkSiiLogo,
      branchBorderColor: "#0464a8",
      mainBorderColor: "#0464a8",
      content: [
        "Ulepszanie bot'a inwestycyjnego pod automatyczne inwestowanie wyzwalane zewnętrznymi wiadomościami / sygnałami (Python / Mexc API / Kucoin API / Telegram API / OpenAI API)",
        "Konserwacja inrastruktury (Terrafrom / Azure / Hetzner)",
        "Konserwacja infrastruktury mikroserwisowej (Docker / Kubernetes / Helm)",
        "Konserwacja serwerów (Linux)",
        "Automatyzacja konfiguracji serwerów (Ansible / Kubespray / Bash / Python)",
        "Ulepszanie / konserwacja job'ów (Jenkins)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#0464a8",
      mainBorderColor: "#0464a8",
    },
    {
      type: "titleSegment",
      title: "Zainteresowania",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Web3.0",
      image: "", //InterestsBlockchainLogo,
      firstElement: true,
      branchBorderColor: "black",
      mainBorderColor: null,
      content: [
        "Blockchain",
        "Cryptography",
        "Smart Contracts",
      ],
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Grafika 2D & 3D",
      image: "", //InterestsBlenderLogo,
      branchBorderColor: "#ff7021",
      mainBorderColor: null,
      content: [
        "Blender",
        "Photoshop",
        "Midjourney",
      ],
    },
    {
      type: "RODOSegment",
      title: "",
      content: "Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do realizacji procesu rekrutacji zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO)."
    }
  ]

export const content_en = [
    {
      type: "generalTitleSegment",
      title: "Kamil Żuk",
      image: MePng,
      content: {
        "E-mail": "zukkamil.44@gmail.com",
        "Github": "git.00x097.com/tbs093a",
        "Education": "Higher / Engineer",
        "Place of Residence": "Rzeszów / Poland",
        "Date of Birth": "30.06.1997",
      }
    },
    {
      type: "titleSegment",
      title: "Experience",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Sii Sp. z o. o. - from 10.11.2022",
      firstElement: true,
      image: WorkSiiLogo,
      content: []
    },
//    {
//      type: "createBranchSegment",
//      tabs: 1,
//      branchBorderColor: "#0464a8",
//      mainBorderColor: "#0464a8",
//    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Inside Projects - DevOps Engineer & Python Developer - from 01.11.2024",
      image: WorkSiiLogo,
      branchBorderColor: "#0464a8",
      mainBorderColor: "#0464a8",
      content: [
        "Improving crypto / stock exchange bot for automatic investment triggered by outside messages / signals (Python / Mexc API / Kucoin API / Telegram API / OpenAI API)",
        "Inrastructure maintenance (Terrafrom / Azure / Hetzner)",
        "Microservices infrastructure maintenance (Docker / Kubernetes / Helm)",
        "Servers maintenance (Linux)",
        "Automatization of servers configuration (Ansible / Kubespray / Bash / Python)",
        "Upgrading / maintainance job processes (Jenkins)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#0464a8",
      mainBorderColor: "#0464a8",
    },
    {
      type: "createBranchSegment",
      tabs: 1,
      branchBorderColor: "#fa0c00",
      mainBorderColor: "#0464a8",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Adobe Inc - DevOps Engineer & Site Reliability Engineer - from 01.12.2023 to 01.11.2024",
      image: WorkAdobeLogo,
      branchBorderColor: "#fa0c00",
      mainBorderColor: "#0464a8",
      content: [
        "Adobe Experience Manager (AEM) Outage Root Cause Analysis (RCA) Preparation",
        "Java Heap-Dumps & Thread-Dumps Analysis (Leak Suspects / Threads Operations Investigation)",
        "SRE Dashboards Analysis (New Relic / Splunk / Grafana)",
        "SRE Dashboards Creation (Splunk)",
        "Automatization of Investigation & Routine Tasks (Bash / Python)",
        "Infrastructure Behaviour Investigation (Kubernetes / Linux / AEM)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#fa0c00",
      mainBorderColor: "#0464a8",
    },
    {
      type: "createBranchSegment",
      tabs: 1,
      branchBorderColor: "#f87479",
      mainBorderColor: "#0464a8",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Techem GmbH - DevOps Engineer - from 30.05.2023 to 30.11.2023",
      image: WorkTechemLogo,
      branchBorderColor: "#f87479",
      mainBorderColor: "#0464a8",
      content: [
        "Microservices infrastructure maintenance (Docker / Docker Compose)",
        "Servers infrastructure maintenance (Jenkins Agent - Windows / Jenkins Master - Linux)",
        "Automatization of servers configuration (Ansible / Bash / Powershell)",
        "Automatization of IrDA devices tests invoking on CI/CD environment (Regression, Merge Request, Commit) (Jenkins CI/CD / Bash / Powershell / Python - TOX)",
        "Automatization of re-used python packages building & collecting process (Jenkins CI/CD / Private PyPI / Python)",
        "Automatization of tests reporting (Jenkins CI/CD / Test-Result-Analyzer / Jira Xray)",
        "Automatization of code validation processes (Pre-commit / Black Formatter / Flake8 / MyPY / etc.)"
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#f87479",
      mainBorderColor: "#0464a8",
    },
    {
      type: "createBranchSegment",
      tabs: 1,
      branchBorderColor: "#0464a8",
      mainBorderColor: "#0464a8",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Inside Projects - DevOps Engineer - from 25.02.2023 to 27.05.2023",
      image: WorkSiiLogo,
      branchBorderColor: "#0464a8",
      mainBorderColor: "#0464a8",
      content: [
        "Microservices infrastructure maintenance (Docker / Kubernetes / Helm)",
        "Servers infrastructure maintenance (Linux)",
        "Automatization of servers configuration (Ansible / Kubespray / Bash)",
        "Upgrading / maintainance of automatic processes (Gitlab-CI)",
        "Automatization of CI/CD process reporting (Gitlab-CI / SonarQube)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#0464a8",
      mainBorderColor: "#0464a8",
    },
    {
      type: "createBranchSegment",
      tabs: 1,
      branchBorderColor: "#8fc5c6",
      mainBorderColor: "#0464a8",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Infinidat Inc - Integration Developer - from 10.11.2022 to 24.02.2023",
      image: WorkInfinidatLogo,
      //lastSubElement: true,
      branchBorderColor: "#8fc5c6",
      mainBorderColor: "#0464a8",
      content: [
        "Infrastructure maintaining (VMware / Vcenter / Vsphere / ESXi)",
        "Jenkins nodes implamentation (with different Linux and Windows distros versions) for applications building and integration (CI/CD process)",
        "Vcenter / Vsphere / ESXi hosts deployment",
        "Problems solving on integration stage (tests / code fixing & repairing)",
        "Integration processes & solution improvements (Jenkins pipeline / Gitlab / Python / Bash)",
        "Routine operations automation (Bash / Python / Ansible / Jenkins)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#8fc5c6",
      mainBorderColor: "#0464a8",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Sembot Sp. z o. o. - from 01.11.2020 to 30.10.2022",
      image: WorkSembotLogo,
      content: []
    },
    {
      type: "createBranchSegment",
      tabs: 1,
      branchBorderColor: "#f5c314",
      mainBorderColor: "#f5c314",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "DevOps Engineer & Python Developer",
      image: "",
      //lastSubElement: true,
      branchBorderColor: "#f5c314",
      mainBorderColor: "#f5c314",
      content: [
        "Server Administration (OVH / AWS / Digital Ocean)",
        "Server Configuratiion (Nginx (Https-Protal) / Apache / Docker)",
        "Routine operations automation (Ansible / Jenkins pipeline / Bash scripts)",
        "Applications deployment oriented on containers (Docker / Docker-Compose)",
        "MerchTech tools implementation (Python / Django / Django REST Framework)",
        "Production databases implementation & configuration & administration for MerchTech tools (Big Data) (MongoDb / Elasticsearch / MariaDb / MySQL / Redis / AWS SQS)"
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#f5c314",
      mainBorderColor: "#f5c314",
    },

    {
      type: "workSubSegment",
      tabs: 0,
      title: "University of Rzeszów - Engineer Studying - IT - from 2017 to 2021",
      image: WorkURLogo,
      content: []
    },
    {
      type: "createBranchSegment",
      tabs: 1,
      branchBorderColor: "#015198",
      mainBorderColor: "#015198",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Thesis - Graphical database of handshapes for the Polish Sign Language (PSL) gesture recognition system",
      image: "",
      //lastSubElement: true,
      branchBorderColor: "#015198",
      mainBorderColor: "#015198",
      content: [
        "Django / Django REST Framework integration with 3D objects editor software - Blender",
        "Djnago / Django REST Framework integration with MongoDb sharded database",
        "WebSocket (Channles 3.0 module) implementation for single proccess of handshape render monitoring",
        "Sync Django REST Framework API implementation for ready materials and 3D object files managment",
        "Async Django + Channels 3.0 + Redis  API implementation for live rendering proccess managment",
        "ReactJS / Gatsby + Redux Toolkit client application implementation for simple sync & async backend application functionality control",
        "Application deployment in distributed form as containers with Docker + Docker-Compose tools",
        "Bash scripts implementation for routain operations automatization like databases migration & all app containers configuration",
        "Made 3D hand model for realistic materials (handshapes) rendering as images for learning PSL recognition system",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#015198",
      mainBorderColor: "#015198",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "ZSE in Rzeszów / Technical School - IT - from 2013 to 2017",
      image: WorkZSELogo,
      noElements: true,
      content: [],
    },
    {
      type: "titleSegment",
      title: "Tech Skills",
    },{
      type: "workSubSegment",
      tabs: 0,
      title: "Programming - Python",
      image: "", //SkillsPythonLogo,
      branchBorderColor: "#ffd748",
      mainBorderColor: null,
      firstElement: true,
      content: [
        "Django",
        "Django REST Framework",
        "Channels 3.0",
        "Celery (Task Manager)",
        "Asyncio",
      ],
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "DevOps",
      image: "", //SkillsDevOpsLogo,
      mainBorderColor: null,
      content: [],
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Automatization",
      image: "", //SkillsBashLogo,
      branchBorderColor: "#4aa720",
      mainBorderColor: "#2e8cb1",
      content: [
        "Jenkins (CI/CD / Routine Processes Automatization (Job's) / GitOps)",
        "Ansible (Automatization of servers & dev environments configuration)",
        "Bash / ZSH (Linux)",
        "Powershell (Windows)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#4aa720",
      mainBorderColor: "#2e8cb1",
    },

    {
      type: "workSubSegment",
      tabs: 1,
      title: "Containerization",
      image: "", //SkillsContainersLogo,
      branchBorderColor: "#00b7ff",
      mainBorderColor: "#2e8cb1",
      content: [
        "Docker",
        "Docker-Compose (Microservices oriented on single servers or debug purposes)",
        "MiniKube (Local debugging K8S manifests)",
        "Kubernetes (Helm / Cert-Manager / Ingress / K9S)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#00b7ff",
      mainBorderColor: "#2e8cb1",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Configuration",
      image: "", //SkillsNginxLogo,
      branchBorderColor: "#d90000",
      mainBorderColor: "#2e8cb1",
      content: [
        "Apache",
        "Nginx (https-portal)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#d90000",
      mainBorderColor: "#2e8cb1",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Experience with Cloud Providers",
      image: "", //SkillsCloudProvidersLogo,
      branchBorderColor: "#f96900",
      mainBorderColor: "#2e8cb1",
      content: [
        "Hetzner",
        "OVH",
        "AWS",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#f96900",
      mainBorderColor: "#2e8cb1",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Databases Configuration & Administration",
      image: "", //SkillsDatabasesLogo,
      mainBorderColor: null,
      content: [],
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Relational Databases (SQL)",
      image: "", //SkillsRelationDbLogo,
      branchBorderColor: "#7b00b3",
      mainBorderColor: "#1038a7",
      content: [
        "MariaDB",
        "MySQL",
        "PostgreSQL",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#7b00b3",
      mainBorderColor: "#1038a7",
    },
    {
      type: "workSubSegment",
      tabs: 1,
      title: "Document-Oriented Databases (NoSQL)",
      image: "", //SkillsDocumentDbLogo,
      branchBorderColor: "#047900",
      mainBorderColor: "#1038a7",
      content: [
        "MongoDB (Sharded Cluster)",
        "ElasticSearch (ElasticSearch / Kibana / Logstash)",
        "Redis (Cache / Task Queue)",
        "RabbitMQ (Task Queue)",
      ],
    },
    {
      type: "mergeBranchSegment",
      tabs: 1,
      branchBorderColor: "#047900",
      mainBorderColor: "#1038a7",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "English - B2 Level",
      image: "", //SkillsEnglishLogo,
      mainBorderColor: null,
      lastElement: true,
      content: [],
    },
    {
      type: "titleSegment",
      title: "Interests",
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "Web3.0",
      image: "", //InterestsBlockchainLogo,
      firstElement: true,
      branchBorderColor: "black",
      mainBorderColor: null,
      content: [
        "Blockchain",
        "Cryptography",
        "Smart Contracts",
      ],
    },
    {
      type: "workSubSegment",
      tabs: 0,
      title: "2D & 3D Graphic Design",
      image: "", //InterestsBlenderLogo,
      branchBorderColor: "#ff7021",
      mainBorderColor: null,
      content: [
        "Blender",
        "Photoshop",
        "Midjourney",
      ],
    },
    {
      type: "RODOSegment",
      title: "",
      content:  "I agree to the processing of personal data provided in this document for realising the recruitment process pursuant to the Personal Data Protection Act of 10 May 2018 (Journal of Laws 2018, item 1000) and in agreement with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)."
    },
  ]


export default {
    content_pl,
    content_en
}
